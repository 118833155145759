
import { PROPERTY_CONSTANTS } from '@nationwide/dgs-internet-servicing-policy-common';

export const environmentCommon = {
    apigeeEnvironment: 'https://api-test.nwie.net/',
    production: false,
    APIs: {
        apigeeEnvironment: 'https://api-test.nwie.net/',
        experience: 'policymanagement/internetservicing-policy/v1/policies/',
        experienceAuto: 'policymanagement/internetservicing-policy/v1/auto-policies/policies/',
        experienceAutoServicing: 'policymanagement/internetservicing-policy/v1/auto-policies/policy-changes/',
        experienceAutoServicingRenewals: 'policymanagement/internetservicing-policy/v1/auto-policies/renewals/',
        autoPolicies: 'policy-management/auto-policies/v1/policies/',
        autoPoliciesServicing: 'policy-management/auto-policy-change/v1/policy-changes/',
        autoPoliciesServicingRenewals: '/policy-management/auto-policy-change/v1/renewals/',
        vinDetails: 'underwritingmanagement/vehicledetails/v1/vehicledetails/',
        vehicleMakes: 'underwritingmanagement/vehicleinquiry_1/v1/vehicleModelMakes',
        vehicleModelWithDescription: 'underwritingmanagement/vehicleinquiry_1/v1/vehicleModelsWithDescription',
        vehicleModelSeries: 'underwritingmanagement/vehicledetails/v1/vehicleSeriesOdbi/',
        ecif: 'customer-information-management/enterprise-customer/v1/',
        updateCTI: 'policymanagement/internetservicing-policy/v1/auto-policies/cx-journey-transaction',
        quickQuoteExperienceAuto: 'policymanagement/internetservicingpolicy-tlexp/v1/auto-policies/policies/',
        quickQuoteExperienceAutoServicing: 'policymanagement/internetservicingpolicy-tlexp/v1/auto-policies/policy-changes/',
        homeownerPolicies: 'policy-management/homeowner-policies/v2/policies/',
        experienceHomeowner: 'policymanagement/internetservicing-policy/v1/homeowners/policies/',
        homeownerPoliciesServicing: '/policy-management/homeowner-policy-change/v2/policy-changes/',
        experienceHomeownerServicing: 'policymanagement/internetservicing-policy/v1/homeowners/policy-changes/',
        mortgageCompanies: 'customer-acquisition/mortgage-companies/v1/mortgage-companies',
        dwellingFirePolicies: 'policy-management/dwelling-fire-policies/v2/policies/',
        experienceDwellingFire: 'policymanagement/internetservicing-policy/v1/dwelling-fire-policies/policies/',
        condoPolicies: 'policy-management/condominium-policies/v2/policies/',
        experienceCondo: 'policymanagement/internetservicing-policy/v1/condo-policies/policies/',
        tenantPolicies: 'policy-management/tenant-policies/v2/policies/',
        experienceTenant: 'policymanagement/internetservicing-policy/v1/tenant-policies/policies/',
        claimsAPI: 'claims-administration/pncclaimsadministration/v1/claims/search',
        umbrellaPolicies: 'policy-management/umbrella-policies/v1/policies/',
        experienceUmbrella: 'policymanagement/internetservicing-policy/v1/umbrella-policies/policies/',
        motorcyclePolicies: 'policy-management/motorcycle-policies/v1/policies/',
        experienceMotorcycle: 'policymanagement/internetservicing-policy/v1/motorcycle-policies/policies/',
        recreationalVehiclePolicies: 'policy-management/recreational-vehicle-policies/v1/policies/',
        experienceRecreationalVehicle: 'policymanagement/internetservicing-policy/v1/recreational-vehicle-policies/policies/',
        boatPolicies: 'policy-management/boatowner-policies/v1/policies/',
        experienceBoat: 'policymanagement/internetservicing-policy/v1/boatowner-policies/policies/',
        cxJourney: 'policymanagement/internetservicing-policy/v1/auto-policies/cx-journey-transaction',
        predictiveCoverages: 'policymanagement/predictive/v1',
        contextCache: 'security-processing/context-cache/v1/contexts',
        distributionPartner: 'operations-management/internet-servicing/v1/distribution-partner-management/distributionpartnerroles/',
        pbsInternetServicing: 'operations-management/internet-servicing/v1/billing-accounts/',
        digitalWalletResourceAPI: 'digital-wallet/v1/'
    },
    smartride: 'https://www.sre.t.awspubliccloud.nationwide.com/smartride/token',
    smartMiles: 'https://www.sre.t.awspubliccloud.nationwide.com/smartmiles/token',
    accountSummaryPage: 'https://st-myaccount.nationwide.nwie.net/myaccount/portfolio/RetrievePortfolio.action',
    personalInformation: 'https://st-myaccount.nationwide.nwie.net/myaccount/profile/RetrieveUserContactInformation.action',
    preferences: 'https://st-myaccount.nationwide.nwie.net/myaccount/preferences/RetrieveUserContactPreferences.action',
    contactUs: 'https://st-myaccount.nationwide.nwie.net/myaccount/portfolio/RetrieveContactDirectory.action',
    mua: 'https://st-myaccount.nationwide.nwie.net/myaccount/preferences/RetrieveManageAuthorizedUsers.action',
    claimsList: 'https://www.claims.t.nationwide.com/fileclaim/info/claim-list-page?pkey=POLICY_NUMBER&destinationPageName=claims-list#/redirect',
    claimsListProperty: 'https://selfservicest.nwie.net/claims/index.html?pkey=POLICY_NUMBER&destinationPageName=property-claims-list#/redirect',
    logout: 'https://iam-st-cnp.apps.nwie.net/access/web/login.htm',
    login: 'https://iam-st-cnp.apps.nwie.net/access/web/login.htm',
    timeout: 'https://iam-st-cnp.apps.nwie.net/access/web/login.htm',
    authorizationOverride: 'https://api-test.nwie.net/operations-management/internet-servicing/v1/policies/',
    paperlessBilling: 'https://st-myaccount.nationwide.nwie.net/myaccount/preferences/RetrieveUserContactPreferences.action?bkey=',

    /** ***********policyCenterTesting***************/
    usePCHeader: true,
    policyCenterHeaderURL: 'https://pls19.plpolicycenter.apps.nwie.net',
    policyCenterShortName: 'pls19',
    pcSystemClockDate: 'https://api-int-test.nwie.net/customer-acquisition/clocks/v1/clocks',

    /** **** INTERNAL ******/
    bootstrap: '//tags.nationwide.com/test/Bootstrap.js',
    cookieBanner: '//consent.trustarc.com/notice?domain=cm-nationwide.com&c=teconsent&js=nj&noticeType=bb&text=true&pcookie&gtm=1',

    /** **** Quick Login Links ******/
    quickContactUs: 'https://staging.nationwide.nwie.net/personal/contact/',
    nationwideURL: 'https://staging.nationwide.nwie.net/',
    personalLoginURL: 'https://iam-st-cnp.apps.nwie.net/access/web/login.htm',

    /** *********** Contentful ***********/
    contentful: {
        space: '5tfs3g6zn870',
        environment: 'master',
        accessToken: 'yYKEGfmIHurSTAdUGQiMCq6EU0mJNQHeeGe5oweLt0k',
        contentfulDeliveryAccessToken: 'yYKEGfmIHurSTAdUGQiMCq6EU0mJNQHeeGe5oweLt0k',
        contentfulPreviewAccessToken: '3IgbbYMcdqJ9QpueQJaDpXBNwfJvgeGYmWEFOeOv8oE',
        localFileLocation: '/contentful/',
        localImageLocation: '/contentful/images/'
    },

    /** **** ICP / Billing *****/
    payMidTermChange: 'https://isb.t.nationwide.com/#/personal/pay-bill/landing?pkey=POLICY_NUMBER&bkey=',
    viewBill: 'https://isb.t.nationwide.com/#/personal/billing/details?bkey=',
    makePayment: 'https://isb.t.nationwide.com/#/personal/pay-bill/landing?bkey=',
    autoPayment: 'https://isb.t.nationwide.com/#/personal/billingMigration/payment-preferences?bkey=',
    stopPayment: 'https://isb.t.nationwide.com/#/personal/billingMigration/payment-preferences/manage/scheduled-future-payments/cancel/landing?bkey=',
    cancelPayment: 'https://isb.t.nationwide.com/#/personal/billingMigration/payment-preferences/manage/scheduled-future-payments/cancel/landing?bkey=',
    billsPayment: 'https://isb.t.nationwide.com/#/personal/billing/details?bkey=',
    billingDetails: 'https://isb.t.nationwide.com/#/personal/billing/details?bkey=',
    paymentPreferences: 'https://servicingst.nwie.net/policyservicing/paymentPreferences.action',
    IAM_CAM_PAPERLESS_URL: 'https://servicingst.nwie.net/policyservicing/documentSummary.action',
    homeownersDiscounts: 'https://servicingst.nwie.net/policyservicing/ispLoadPropertyDiscounts.action?pkey=',
    homeownersCoverages: 'https://servicingst.nwie.net/policyservicing/ispLoadPropertyCoverages.action?pkey=',
    homeownersDocuments: 'https://servicingst.nwie.net/policyservicing/ispLoadPropertyDocuments.action?pkey=',
    propertyClaims: 'https://claimsst.apps.nwie.net/claims/index.html?pkey=POLICY_NUMBER&destinationPageName=property-claims-list#/redirect',

    CLIENT_LOGGING_API: {
        ENDPOINT: 'https://api-test.nwie.net/it-management/client-logging/v1/client-logs',
        PUSH_LENGTH: 5
    },

    /** **** COMMERCIAL *****/
    commercialQuickId: 'https://iscomm.t.nationwide.com/commercial/#/quickIdCards',

    /** ** Global Nav Bill Options ****/
    childURLs: {
        isbBase: 'https://isb.t.nationwide.com',
        icpBase: 'https://servicingst.nwie.net/',
        ispBase: `${window.location.origin}/`,
        sreBase: 'https://www.sre.t.awspubliccloud.nationwide.com/'
    },

    /** ****************GIS****************************/
    gisURL: 'place/v1/address',
    latLong: 'rating/geographicratingfactors/v3/ratingfactors',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    latLong_v4: 'rating/geographicratingfactors/v4/ratingfactors',
    /** **** SERVICE ******/
    SERVICE: {
        rtbcEndpoint: 'billing-collections/personal-lines-billing/v2/billing-accounts/{{billingAccount}}/$metadata/bill-calculator',
        billingEndpoint: 'billing-collections/personal-lines-billing/v2/billing-accounts/{{billingAccount}}',
        accountBillsEndpoint: 'billing-collections/personal-lines-billing/v2/billing-accounts/{{billingAccount}}/bills',
        ctmApiEndpoint: 'customerinformationmanagement/contactmanagement/v1/interactions',
        rcaEndpoint: 'accountagreementmanagement/portfoliobusinessservice/v1/customeragreement',
        rcawrEndpoint: 'accountagreementmanagement/portfoliobusinessservice/v1/customeragreementwithrefresh',
        rcpEndpoint: 'accountagreementmanagement/portfoliobusinessservice/v1/customerportfolio',
        searchPhoneNumberEndPoint: 'accountagreementmanagement/portfoliobusinessservice/quick-auth/v1/searchPhone'
    },

    CLICK_TO_CHAT: {
        baseEndpointUrl: 'https://api-test.nwie.net:443/communication/',
        chatApiPath: 'chat/v1/ICP',
        multiStatApiPath: 'stat/v1/internal_statistics',
        apiKey: 'xG08ElLbw1cLkkJm89jdX9SkMdcjkLoW',
        statsAgentsObjectId: '_C2Chat_VAG',
        statsQueueObjectId: '_C2Chat_VQ@mcr',
        isEnabled: true,
        cookieDomain: 'nwie.net'
    },

    CLICK_TO_CHAT_VIVR: {
        baseEndpointUrl: 'https://api-test.nwie.net:443/communication/',
        apiKey: 'xG08ElLbw1cLkkJm89jdX9SkMdcjkLoW',
        statsAgentsObjectId: 'PLSC_vIVR_Servicing_C2Chat_VAG',
        statsQueueObjectId: 'PLSC_vIVR_Servicing_C2Chat_VQ@mcr',
        cookieDomain: 'nwie.net'
    },

    authorize: {
        oauthEndpoint: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize',
        oauthTokenEndpoint: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/token',
        oauthRedirectHandlerPath: '/assets/html/eua-callback.html',
        client_id: 'IkfxfmZ0dsGNPs7MerkpPc5T4G77LaPd',
        eua_id: 'SWtmeGZtWjBkc0dOUHM3TWVya3BQYzVUNEc3N0xhUGQ6SGpNQ1RIcWRqR01IaW1DcQ==',
        nonce: 'myNonce',
        responseType: 'id_token token',
        scope: 'test',
        realm: 'member',
        loginTokenKey: '',
        loginTokenParam: '',
        authTimeout: 20,
        debug: false,
        excludedRoutes: ['/quick/login/add-vehicle', '/quick/add-vehicle', '/quick/coverages', '/personal/policy/policy-stub', '/personal/policy/error-404', '/ivr/login', '/ivr/picker'],
        refreshThresholdSeconds: PROPERTY_CONSTANTS.minutesToTimeoutWarning * PROPERTY_CONSTANTS.SECONDS_IN_MINUTE,
        loginTokenSessionStorageLocation: '',
        auth_method: 'ping',
        runningNestedIframes: false,
        ttl: 1200,
        renewalTokenEndpoint: 'https://identity.nwie.com/bsa-login-refresh/refresh?instance=BSAOAuthB2CNW&resumeUrl='
    },

    DOCUMENTS: {
        documentOpenerEndpoint: 'policymanagement/open-documents/v1/document',
        documentBytestreamEndpoint: 'policymanagement/open-documents/v1/get-document',
        mobiusEndpoint: 'communication/mobiusviewapi/v1/',
        docusignApiEndpoint: 'policy-management/servicing-docusign-experience/v1/',
        repoId: 'RDCPROD',
        mobiusRepoId: 'Columbus',
        consumerId: 'ESIG'
    },

    ENVIRONMENT_SPECIFIC_PROVIDERS: [],

    LOGGING: {
        level: 'debug', /* debug, info, warn, err */
        logJSON: 'OBJECTS', /* can be true, false, 'OBJECTS', or 'SHORT' */
        logToConsole: true
    },
    env: 'localhost', /* localhost, ci, dev[n], it, st, pt, prod */
    skipPCErrorFormatting: true,
    useStub: false,
    useMockDocuments: false,
    useMockGlobalNav: false,
    bypassExperienceApi: false,
    useC2Chat: true,
    useBillingWidget: true,
    esignMaxRetryCount: 3,
    applicationName: 'dgs-internet-servicing-policy',
    billingConnectionType: 'CIB1',
    clickToCall: {
        area: '877',
        centralOffice: '878',
        subscriber: '2621'
    },
    idTexting: {
        allowedLoginAttempts: 5,
        login: 'https://iam-st-cnp.apps.nwie.net/access/web/login.htm',
        quickServicingAuthMethod: 'quick-servicing',
        euaResponseType: 'id_token%20token',
        euaRealm: 'unidentified',
        scope: 'test',
        euaWorkflow: 'idt',
        euaClientId: 'IkfxfmZ0dsGNPs7MerkpPc5T4G77LaPd'
    }

};

