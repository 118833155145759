import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import {
    DistributionPartnerInformationServiceConfig,
    distributionPartnerInformationServiceConfigToken,
    DpimInformationRoleModule
} from '@nationwide/internet-servicing-dpim-requests';
const distributionPartnerInformationServiceConfig: DistributionPartnerInformationServiceConfig = {
    retrieveDistributionPartnerInfoUrl: (agentIndentifier: string, query: string): string => `${environment.apigeeEnvironment}${environment.APIs.distributionPartner}${agentIndentifier}?${query}`,
    clientId: environment.authorize.client_id
};
@NgModule({
    imports: [
        DpimInformationRoleModule
    ],
    providers: [
        { provide: distributionPartnerInformationServiceConfigToken, useFactory: (): DistributionPartnerInformationServiceConfig => distributionPartnerInformationServiceConfig }
    ]
})
export class DpimInformationModule { }
